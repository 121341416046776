<template>
  <nav-header fixed title="操作案例" />
  <van-form @submit="onSubmit">
    <div class="padding-top-15">
      <van-cell-group inset>
        <van-field
            required
            v-model="form.title"
            label="案例名称"
            placeholder="请输入案例名称"
        />
        <fu-wu-shang-type
            v-model:id="form.type_id"
            v-model:name="form.type_name"
            input-type="radio"
            required
            title="案例类型"
        />
        <Address
            title="案例地区"
            v-model:id="form.address_id"
            v-model:name="form.address_name"
        />
      </van-cell-group>
    </div>
    <div class="padding-top-15">
      <van-cell-group inset>
        <van-field
            label="案例图片"
        >
          <template #input>
            <add-img :bi-li="1/0.618" :cai-jian="true" length="6" v-model:form="form.image"/>
          </template>
        </van-field>
      </van-cell-group>
    </div>
    <div class="padding-top-15">
      <van-cell-group inset>
        <van-field
            required
            rows="10"
            type="textarea"
            v-model="form.content"
            label="案例描述"
            placeholder="请输入案例描述"
        />
      </van-cell-group>
    </div>
    <div class="form-btn">
      <van-button round block type="primary" native-type="submit">
        提交
      </van-button>
    </div>
    <div class="ti-shi">
      温馨提示：<br/>
      请不要写铭感信息，否则会被平台抽查到强制驳回。
    </div>
  </van-form>
</template>

<script>
import AddImg from "../../components/file/AddImg";
import FuWuShangType from "../../components/select/FuWuShangType";
import http from "../../api/http";
import din_shi from "../../api/din_shi";
import Address from "../../components/select/Address";
import XEUtils from "xe-utils";
import NavHeader from "../../components/top/NavHeader";

export default {
  name: 'UserCaseEdit',
  components: {NavHeader, FuWuShangType, AddImg, Address},
  mixins: [],
  data() {
    return {
      form: {},
      id: this.$route.query.id
    }
  },
  mounted() {
    this.setDefault()
  },
  methods: {
    setDefault() {
      if (XEUtils.isUndefined(this.id)) return;

      http.get("/gzhphp/user_case/getInfo", {params: {id: this.id}}).then(da => {
        if (da.code === 1) {
          this.form = da.data
        }
      })
    },
    onSubmit() {
      http.post('/gzhphp/user_case/edit', this.form).then(da => {
        if (da.code === 1) {
          din_shi.success(da.msg).then(() => {
            this.$router.push({name: 'CaseList'})
          })

        }
      })
    }
  },
  watch: {},
  computed: {}
}
</script>

<style scoped lang="less">
@import "../../assets/basis_css/style/var";

.ti-shi {
  color: @pei-se;
  margin: 30px 10px 10px;
  padding-bottom: 30px;
}
</style>
